.container-aluno {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background-image: linear-gradient(#004f80, #0568a6);
}

.container-aluno .container {
    min-width: 70%;
    max-width: 95%;
    max-height: 95%;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dedede;
    padding: 16px;
    overflow: auto;
}

.container-aluno .header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.container-aluno .header-title {
    display: flex;
    align-items: center;
}

.container-aluno .div-avatar {
    height: 40px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #dedede80;
    border: 1px solid #dedede;
    border-radius: 25px;
    cursor: pointer;
}   

.container-aluno .div-avatar:hover{
    background-color: #dedede;
}

.container-aluno .div-porcentagem {
    padding: 10px 0px;
    position: sticky;
    top: -16px;
    background-color: #fff;
    z-index: 1;
}

.button-cadastro {
    text-align: right;
}

@media screen and (max-width: 576px) {
    .container-aluno .header {
        flex-direction: column;
        align-items: flex-start;
    }
    .container-aluno .div-avatar {
        margin-top: 10px;
    } 
    .button-cadastro {
        text-align: left;
    }
    
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 992px) {
    .container-aluno .container {
        max-width: 70%;
    }
    
}

@media screen and (min-width: 1200px) {
    
}

@media screen and (min-width: 1364px) {
    
}
.dashboard_div_card {
    width: 100%;
    padding: 8px 16px;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dedede;
}

.dashboard_div_card .title {
    font-weight: 300;
    font-size: 18px;
    color: #024f80;
    margin-top: 5px;
}

.dashboard_div_card .subtitle {
    font-size: 40px;
    font-weight: bold;
    color: #024f80;
    text-align: end;
}

.dashboard_div_card .header {
    display: flex;
    flex-direction: column;
    /* justify-content: space-between; */
    /* align-items: center; */
}

.dashboard_div_card .header div{
    margin: 5px 0px;
}

.card-info-dashboard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    padding: 8px;
    font-size: 12px;
    border-radius: 10px;
    height: 100%;
    border: 1px solid #efefef;
    background-color: #efefef60;
}

.card-info-dashboard .container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    font-size: 10px;
}

.card-info-dashboard .container-porcentagem {
    flex: 1;
    display: flex;
    margin: 0px 8px;
    height: 20px;
}

.card-info-dashboard .porcentagem {
    height: 100%;
    font-size: 10px;
    display: flex;
    align-items: center;
}


@media screen and (min-width: 420px) {
    .dashboard_div_card .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
    .dashboard_div_card .header div{
        margin: 0px;
    }
}
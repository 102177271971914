.container-recupera-senha {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(#004f80, #0568a6);
}

.div-form-recupera-senha {
    padding: 8px 16px;
    border: 1px solid #dedede;
    border-radius: 4px;
    background-color: #fff;
}

.nav-bncc {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    background-color: #004f80;
    display: flex;
    align-items: center;
}

.nav-bncc .div-user {
   display: none;
}

@media screen and (min-width: 576px) {
    .nav-bncc .div-user {
        display: flex;
        align-items: center;
        padding: 5px 8px 5px 8px;
        background-color: #05436b;
        border-radius: 5px;
        cursor: pointer;
        color: #fff;
    }
 }
 
 @media screen and (min-width: 768px) {
 }
 
 @media screen and (min-width: 992px) {
     
 }
 
 @media screen and (min-width: 1200px) {
     
 }
 
 @media screen and (min-width: 1364px) {
     
 }





@media screen and (min-width: 320px) {

    .nt-modal-share {
        position: fixed;
        
        inset: 0px;
        z-index: 200;
        /* background-color: rgba(255, 255, 255, 0.75); */
        background-color: #00000099
    }
     
    .nt-content-modal{
        width: 300px !important;
        z-index: 200 !important;
    }
    
    .nt-modal-title {
        font-size: 22px;
        font-weight: 600;
        color: #1e232b;
    }
    
    .nt-modal-footer{
        padding: 10px 0px 0px;
        width: 100%;
        display: flex;
        justify-content:flex-end;
    }
    
    .nt-modal-body {
        max-height: 330px;
        overflow-y: auto;
    }
    
    .nt-modal-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 10px;
        border: 1.5px solid #dedede;
        border-radius: 10px;
    }
    
    .nt-modal-row p {
        font-size: 18px;
        font-weight: 400;
        color: #1e232b;
    }
    
    .nt-div-link-copy {
        background-color: #3d3a3a;
        height: 40px;
        width: 40px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    
    .nt-button-share {
        background-color: #fff;
        border: 1.5px solid #305EA3;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 5px 8px;
        border-radius: 5px;
        width: 145px;
        margin: 0px ;
        cursor: pointer;
    }
    
    .nt-button-share p {
        color: #305EA3;
        font-weight: bold;
        margin-right: 5px;
    }
    
    }
    
    @media screen and (min-width: 576px) {
    
        .nt-content-modal{
            width: 400px !important;
        }
        .nt-modal-body {
            max-height: 330px;
        }
        
        
    }
    
    @media screen and (min-width: 768px) {
       
    }
    
    @media screen and (min-width: 992px) {
        
    }
    
    @media screen and (min-width: 1200px) {
        
    }
    
    @media screen and (min-width: 1364px) {
        
    }
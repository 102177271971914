a.item-navegacao {
    padding: 0px;
    color: black;
    text-decoration: none;
}

a.item-navegacao:hover {
    color: black;
}

a.item-navegacao>div.Mui-selected {
    background-color: #445474 !important;
    color: white;
}

a.item-navegacao>div.Mui-selected>div>svg {
    color: white;
}

/* .css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root.Mui-selected {
    background-color: #445474!important;
    color: white;
}

.css-cvhtoe-MuiButtonBase-root-MuiListItemButton-root.Mui-selected svg {
    color: white;
} */
.background-style {
    background-color: #F9FAFC;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

/* width */
::-webkit-scrollbar {
    width: 6px;
}
::-webkit-scrollbar:horizontal {
    height: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #F9FAFC;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: rgba(200, 200, 200, 0.4);
    border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: rgba(180, 180, 180, 0.4);
}

.drawer-header {
    margin: 0px 0px 20px ;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.drawer-header span {
    font-size: 1.25rem;
    font-weight: 500;
}

.container-bncc {
    padding: 0px 60px !important;
}


@media screen and (min-width: 320px) {

    .menu-drawer {
        max-width: 320px;
        min-height: 320px;
        height: 100%;
        padding: 10px 16px 16px;
        box-sizing: border-box;
    }

    .menu-drawer-user {
        max-width: 100%;
        padding: 8px 16px;
        margin: 0px 0px 20px 0px;
        background-color: #efefef70 ;
        display: flex;
        align-items: center;
        color: #666;
        cursor: pointer;
    }
}


@media screen and (min-width: 576px) {
    .menu-drawer {
        max-width: 400px;
        min-width: 400px;
    }
  
}

@media screen and (max-width: 600px) {
    
    .container-bncc {
        padding: 0px 20px !important;
    }

}

body {
    background-color: #F9FAFC;
}
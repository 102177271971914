.container-item {
  padding: 10px;
  border: 1px solid #dedede;
  border-radius: 4px;
  background-color: #fff;
  margin-bottom: 8px;
}

.container-item .header {
  display: flex;
  align-items: flex-start;
}

.container-item .questao_index {
  border: 3px solid #004f80;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: #004f80;
}

.container-item .descricao {
  flex: 1;
  margin: 10px 0px 0px 10px;
  font-size: 14px;
}

.container-item .body {
  margin-top: 10px;
}

.container-item .alternativa {
  border: 1px solid #dedede;
  margin-bottom: 8px;
  padding: 16px;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
  display: flex;
}

.container-item .alternativa-professor {
  border: 1px solid #dedede;
  margin-bottom: 8px;
  padding: 16px;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
}

.container-item .alternativa:hover {
  background-color: #efefef70;
}

.container-item .alternativa_descricao {
  font-size: 14px;
  margin-top: 5px;
}

.container-item .alternativa_selecionada {
  border: 2px solid #004f80;
  background-color: #efefef80;
}

.container-item .imagem-descricao {
  margin-top: 10px;
  position: relative;
  cursor: pointer;
  max-height: 300px;
}

.container-item .alternativa .letra {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  border: 1px solid #dedede;
  margin-right: 10px;
  font-weight: bold;
  font-size: 18px;
  color: #004f80;
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

.container-item .alternativa-professor .letra {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  border: 1px solid #dedede;
  margin-right: 10px;
  font-weight: bold;
  font-size: 18px;
  color: #004f80;
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

@media screen and (max-width: 576px) {
  .container-item .imagem-descricao {
    max-width: 100%;
  }
}

.div-title-questao-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.div-container-form-alternativa {
  background-color: #efefef60;
  border: 1px solid #dedede;
  padding: 16px;
  width: 100%;
  margin-left: 16px;
  margin-top: 16px;
  border-radius: 8px;
}

.div-container-alternativa {
  width: 100%;
  border: 1px solid lightslategray;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 4px;
  background-color: #efefef80;
  position: relative;
  margin-bottom: 10px;
}

.div-container-alternativa-text {
  display: flex;
  align-items: center;
}

.div-container-alternativa-index {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightslategray;
}

.div-container-alternativa-index span {
  color: #fff;
  font-size: 22px;
}

.alternativa-descricao {
  font-size: 14px;
  color: #62707e;
  font-weight: bold;
}

.alternativa-imagem {
  width: 300px;
  margin: 0px 10px;
}

.div-card-item {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 5px 0px;
}

.title-card-item {
  font-weight: bold;
  font-size: 16px;
}

.subTitle-card-item {
  color: #666;
  font-size: 12px;
}

.badge-item-card {
  font-size: 10px;
  padding: 4px;
  background-color: #efefef;
  border-radius: 4px;
}

.form-descricao-item .ck-editor__editable_inline {
  min-height: 160px;
}

.form-alternativa .ck-editor__editable_inline {
  min-height: 90px;
}

.imagem-descricao-item {
  margin-top: 10px;
  position: relative;
  cursor: pointer;
  max-height: 300px;
}

.total-itens {
  font-size: 12px;
  background-color: #fff;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #dedede;
}

.alternativa-letra {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  border: 1px solid #dedede;
  margin-right: 10px;
  font-weight: bold;
  font-size: 18px;
  color: #004f80;
  width: 30px;
  height: 30px;
  border-radius: 15px;
}

@media screen and (max-width: 576px) {
  .imagem-descricao-item {
    max-width: 100%;
  }
}

@media (max-width: 400px) {
  .div-title-questao-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .div-container-alternativa {
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding: 10px;
  }

  .div-container-alternativa-text {
    margin-bottom: 10px;
  }

  .div-container-alternativa-index {
    height: 30px;
    width: 30px;
    border-radius: 15px;
  }

  .div-container-alternativa-index span {
    font-size: 12px;
  }

  .alternativa-descricao {
    font-size: 12px;
  }

  .select-alternativa-correta {
    width: 100%;
    margin: 0px 0px 15px 0px !important;
  }

  .alternativa-imagem {
    width: 100%;
    margin: 0px 0px 0px 10px;
  }
}

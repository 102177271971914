.div-container-fileinput {
    border: 1px dashed lightseagreen;
    background-color: #efefef90;
    border-radius: 4px;
    width: 100%;
    height: 200px;
    overflow: hidden;
    cursor: pointer;
}

.div-container-fileinput-hover:hover {
    transition: all 0.5s;
    background-color: #00000080;
    color: #fff;
}

.div-container-fileinput-hover {
    position: absolute;
    top: 0; 
    bottom: 0; 
    left: 0; 
    right: 0;
    background-color: transparent;
    color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}


.div-container-image-name{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color:  #00000040;;
    padding: 10px;
    text-align: center;
}

.div-container-image {
    margin: 8px;
    overflow: hidden; 
    height: 184px; 
    border-radius: 4px;
    position: relative; 
}


.imagem-modal-detalhes {
    max-width: 80vw;
}
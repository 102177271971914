.header-bncc {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header-div-title {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 600px) {
    .header-bncc {
      flex-direction: column;
      align-items: flex-start;
    }

}
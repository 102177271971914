.card-avaliacao-item {
  margin: 4px 4px 8px;
  border: 1px solid #dedede;
}

.card-avaliacao-item .header {
  padding: 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-avaliacao-item .badge-header {
  background-color: #2e7d32;
  padding: 0px 6px 4px;
  border-radius: 4px;
  color: #fff;
}

.card-avaliacao-item .body {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  margin: 5px 0px;
}

.card-avaliacao-item .descricao {
  font-weight: bold;
  font-size: 16px;
}

.card-avaliacao-item .descricao-img {
  height: 160px;
  width: 100%;
  margin: 10px 0px;
}

.card-avaliacao-item .footer {
  margin-top: 10px;
}

.card-avaliacao-item .badge-footer {
  font-size: 12px;
  padding: 4px;
  background-color: #efefef;
  border-radius: 4px;
}

.card-avaliacao-item-selecionado {
  max-height: 140px;
  padding: 8px;
}

.card-avaliacao-item-selecionado .body {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin: 5px 0px;
}

.card-avaliacao-item-selecionado .descricao {
  font-size: 12px;
  font-weight: bold;
}

.card-avaliacao-item-selecionado .footer {
  margin: 10px 0px 5px;
}

.card-avaliacao-item-selecionado .badge-footer {
  font-size: 12px;
  padding: 4px;
  background-color: #efefef;
  border-radius: 4px;
}

.card-avaliacao {
  padding: 8px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-avaliacao .header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.card-avaliacao .badge {
  font-size: 10px;
  padding: 4px;
  background-color: #efefef;
  border-radius: 4px;
}

.card-avaliacao .body {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  margin: 5px 0px;
}

.card-avaliacao .title {
  font-weight: bold;
  font-size: 16px;
}

.card-avaliacao .footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.card-avaliacao .footer-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.total-avaliacoes {
  font-size: 12px;
  background-color: #fff;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid #dedede;
}

.div-item-gabarito {
  margin: 5px 10px 5px 0px;
  text-align: center;
}

.div-item-gabarito .letra {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
  border: 1px solid #dedede;
  font-weight: bold;
  font-size: 18px;
  color: #004f80;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin-top: 5px;
}

.div-item-gabarito .sucesso {
  background-color: #008000 !important;
  color: #fff !important;
}

.div-item-gabarito .erro {
  background-color: red !important;
  color: #fff !important;
}

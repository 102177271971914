.container-login {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: linear-gradient(#004f80, #0568a6);
    padding: 10px;
}

.div-form-login {
    min-width: 450px;
    padding: 16px;
    border: 1px solid #dedede;
    border-radius: 4px;
    background-color: #fff;
}


.div-form-login-img {
    width: 100%;
    margin: 15px 0px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.div-form-login-img span{
    font-size: 18px;
    color: #004f80;
    font-weight: 500;
}

@media screen and (max-width: 576px) {
   .div-form-login {
    min-width: 100%;
   }
}

@media screen and (min-width: 768px) {
}

@media screen and (min-width: 992px) {
    .container-aluno .container {
        max-width: 70%;
    }
    
}

@media screen and (min-width: 1200px) {
    
}

@media screen and (min-width: 1364px) {
    
}